import { observer } from '@legendapp/state/react'
import { cn } from '@my/magic-ui'

import { Cards } from './components/Cards'
import { PaymentTypeModal } from './components/PaymentTypeModal'
import { PeriodTab } from './components/PeriodTab'
import { SubscriptionSectionProvider } from './providers/SubscriptionSectionProvider'
import { useBillingScreenTranslation } from '../../hooks/useBillingScreenTranslation'

export const SubscriptionSection = observer(({ highLight }: { highLight?: boolean }) => {
  const { t } = useBillingScreenTranslation()
  return (
    <SubscriptionSectionProvider>
      <div
        className={cn(
          'relative py-[70px] z-1 overflow-hidden -mx-4 md:-mx-0 px-4 md:px-0',
          highLight
            ? 'before:content-[""] before:absolute before-left-0 before:inset-0 before:bg-gradient-to-r before:from-[#7756E0] before:to-[#0D009B] before:w-[1533px] before:left-1/2 before:-translate-x-1/2'
            : ''
        )}
      >
        <img
          src="https://api.mydreamboy.com/storage/v1/object/public/static/subscription_bg.png"
          className="absolute top-0 left-0 z-0 w-auto h-auto max-w-none max-h-none object-none"
        />
        <div className="relative z-1">
          <p className="text-white text-center text-3xl md:text-5xl font-medium">
            {t('subscriptionSection.title')}
          </p>
          <p className="mt-4 text-white text-center text-sm md:text-base">
            {t('subscriptionSection.desc1')}
          </p>
          <p className="mt-1 text-white text-center text-sm md:text-base">
            {t('subscriptionSection.desc2')}
          </p>
          <PeriodTab />
          <Cards />
        </div>
      </div>
      <PaymentTypeModal />
    </SubscriptionSectionProvider>
  )
})
