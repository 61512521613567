import { useObservable } from '@legendapp/state/react'
import { Subscription_Tier } from '@my/supabase/graphql/gql/graphql'
import constate from 'constate'
import { useRef } from 'react'

import { PERIODS, PaymentType } from '../../../constant'

const useSubscriptionSectionInner = () => {
  const selectedPeriod$ = useObservable(PERIODS[0])
  const selectedTier$ = useObservable<Subscription_Tier | undefined>()
  const paymentType$ = useObservable<PaymentType>(PaymentType.Recurring)
  const paymentMethod$ = useObservable<string | null>(null)
  const onPayment = useRef<(() => void) | null>(null)
  const isPaying$ = useObservable(false)

  return {
    selectedPeriod$,
    selectedTier$,
    paymentType$,
    paymentMethod$,
    onPayment,
    isPaying$,
  }
}

export const [SubscriptionSectionProvider, useSubscriptionSection] = constate(
  useSubscriptionSectionInner
)
