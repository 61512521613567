import { observer, useObservable } from '@legendapp/state/react'
import { cn } from '@my/magic-ui'
import { useBillingScreenTranslation } from 'app/features/ecommerce/hooks/useBillingScreenTranslation'
import { useUser } from 'app/utils/useUser'
import { AnimatePresence, motion } from 'framer-motion'
import { ArrowUpRight, Check, X } from 'lucide-react'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'

import { BilledText, PeriodText } from './Text'

export const CardItem = observer(
  ({
    icon,
    tier,
    credits,
    billPrice,
    benefits,
    highlight,
    decoration,
    className,
    onClick,
  }: {
    icon: React.ReactNode
    tier: string
    credits: number
    billPrice: string
    benefits: number[]
    highlight?: boolean
    decoration?: React.ReactNode
    className?: string
    onClick?: () => void
  }) => {
    const hovered$ = useObservable(false)
    const { t } = useBillingScreenTranslation('subscriptionSection.cards')
    const [prevBillPrice, setPrevBillPrice] = useState(billPrice)
    const { isLogin } = useUser()
    const router = useRouter()
    useEffect(() => {
      if (billPrice !== prevBillPrice) {
        setPrevBillPrice(billPrice)
      }
    }, [billPrice, prevBillPrice])

    return (
      <div
        className={cn(
          'fle-1 flex-shrink-0 relative mt-[67px] md:mt-[95px] px-3 md:px-4 rounded-lg px-[26px] py-[37px] bg-[#0A0D18]',
          highlight ? 'bg-[#0D009B]' : '',
          className
        )}
      >
        {/* tier text */}
        <div className="flex items-center gap-2 md:gap-4">
          <div className="p-2 md:p-[16px] flex items-center justify-center bg-[#17183C] rounded-2xl">
            {icon}
          </div>
          <span className="text-white text-3xl md:text-5xl font-medium">{tier}</span>
        </div>
        {/* price */}
        <div className="mt-[30px] flex flex-col gap-2">
          <p className="flex items-end gap-2">
            <span className="text-3xl md:text-5xl font-medium">{credits}</span>
            <span className={cn('text-base text-white/50', highlight ? 'text-white' : '')}>
              Credits/Mo
            </span>
          </p>
          <div className="relative h-[24px] md:h-[28px]">
            <AnimatePresence>
              <motion.p
                key={billPrice}
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: 20 }}
                transition={{ duration: 0.5 }}
                className="text-sm md:text-base flex items-center gap-2 absolute top-0 left-0"
              >
                <BilledText />
                <span className="rounded-full bg-white text-black p-[2px]" />${billPrice}/
                <PeriodText />
              </motion.p>
            </AnimatePresence>
          </div>
        </div>
        {/* benefit */}
        <div className="mt-[30px] flex flex-col gap-4">
          {(
            t('benefit', {
              returnObjects: true,
            }) as string[]
          ).map((benefit, index) => (
            <BenefitItem
              key={index}
              text={benefit}
              checked={benefits.includes(index)}
              highlight={highlight}
              comingSoon={index > 4}
            />
          ))}
        </div>
        {/* button */}
        <button
          className={cn(
            'mt-[30px] w-full  py-[20px] rounded-full text-white text-lg font-medium flex items-center justify-center gap-2 transition-all duration-300 cursor-pointer',
            highlight ? 'bg-white text-[#0D009B]' : 'bg-gradient-to-r from-[#4A3AFF] to-[#0D009B]'
          )}
          onMouseEnter={() => hovered$.set(true)}
          onMouseLeave={() => hovered$.set(false)}
          onClick={() => {
            if (!isLogin) {
              router.push('/sign-in')
              return
            }
            onClick?.()
          }}
        >
          Upgrade
          <motion.div
            animate={hovered$.get() ? { rotate: 45, scale: 1.1 } : { rotate: 0, scale: 1 }}
            transition={{ duration: 0.3 }}
          >
            <ArrowUpRight className="w-5 h-5" />
          </motion.div>
        </button>
        {decoration}
      </div>
    )
  }
)

const BenefitItem = observer(
  ({
    text,
    checked,
    comingSoon,
    highlight,
  }: {
    text: string
    checked: boolean
    comingSoon?: boolean
    highlight?: boolean
  }) => {
    return (
      <div className="flex items-center gap-2 md:gap-4">
        <div className="flex items-center justify-center">
          <div
            className={cn(
              'p-[1px] md:p-[2px] rounded-full flex items-center justify-center border',
              checked
                ? highlight
                  ? 'bg-white border-white'
                  : 'bg-[#4A3AFF] border-[#4A3AFF]'
                : 'bg-transparent border-white/30'
            )}
          >
            {!checked ? (
              <X
                className={cn(
                  'w-3 h-3 md:w-4 md:h-4 text-white/20',
                  checked ? 'text-[#010510]' : ''
                )}
                strokeWidth={2.5}
              />
            ) : (
              <Check
                className={cn(
                  'w-3 h-3 md:w-4 md:h-4 text-white/20',
                  checked ? (highlight ? 'text-[#0D009B]' : 'text-[#010510]') : ''
                )}
                strokeWidth={2.5}
              />
            )}
          </div>
        </div>
        <span className="text-sm md:text-base">{text}</span>
        {comingSoon && (
          <span className="bg-white/10 px-1 md:px-2 py-1 text-xs md:text-sm rounded">
            Coming Soon
          </span>
        )}
      </div>
    )
  }
)
