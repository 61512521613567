import { YStack } from '@my/ui/src'
import { DiscordLink, DiscordLinkUrl } from 'app/features/socialMedia/DiscordLink/DiscordLink'
import { FacebookLink, FacebookLinkUrl } from 'app/features/socialMedia/FacebookLink/FacebookLink'
import { TwitterLink, TwitterLinkUrl } from 'app/features/socialMedia/TwitterLink/TwitterLink'
import Link from 'next/link'

import { LanguageSwitcher } from './LanguageSwitcher/LanguageSwitcher'

const footerNavs = [
  {
    label: 'Community',
    items: [
      {
        href: DiscordLinkUrl,
        name: 'Discord',
      },
      {
        href: TwitterLinkUrl,
        name: 'Twitter',
      },
      {
        href: FacebookLinkUrl,
        name: 'Facebook',
      },
      {
        href: '/affiliate',
        name: 'Affiliate',
      },
      {
        href: 'mailto:support@mydreamboy.com',
        name: 'Email',
      },
    ],
  },
  {
    label: 'Legal',
    items: [
      { href: '/about', name: 'About' },
      {
        href: '/privacy-policy',
        name: 'Privacy Policy',
      },

      {
        href: '/terms-of-service',
        name: 'Terms of Service',
      },
    ],
  },
]

export function FooterSection() {
  return (
    <footer>
      <YStack position="relative" id="footer" />
      <div className="mx-auto w-full max-w-screen-xl xl:pb-2 mt-24 mb-6">
        <div className="md:flex md:justify-between px-8 p-4 py-16 sm:pb-16 gap-4">
          <div className="mb-12 flex-col flex gap-4">
            <Link href="/" className="flex items-center gap-2">
              <img src="/favicon.svg" className="h-8 w-8 text-primary" />
              <span className="self-center text-2xl font-semibold whitespace-nowrap dark:text-white">
                MyDreamBoy AI
              </span>
            </Link>
            <p className="max-w-xs">AI Porn Generator For Male</p>
            <LanguageSwitcher />
          </div>
          <div className="grid grid-cols-1 gap-8 sm:gap-10 sm:grid-cols-2">
            {footerNavs.map((nav) => (
              <div key={nav.label}>
                <h2 className="mb-6 text-sm tracking-tighter font-medium text-gray-900 uppercase dark:text-white">
                  {nav.label}
                </h2>
                <ul className="gap-2 grid">
                  {nav.items.map((item) => (
                    <li key={item.name}>
                      <Link
                        href={item.href}
                        className="cursor-pointer text-gray-400 hover:text-gray-200 duration-200 font-[450] text-sm"
                      >
                        {item.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>

        <div className="flex flex-col sm:flex-row sm:flex sm:items-center sm:justify-between rounded-md border-neutral-700/20 py-4 px-8 gap-2">
          <div className="flex space-x-5 sm:justify-center sm:mt-0">
            <DiscordLink />
            <TwitterLink />
            <FacebookLink />
          </div>
          <span className="text-sm text-gray-500 sm:text-center dark:text-gray-400">
            Copyright © {new Date().getFullYear()}{' '}
            <Link href="/" className="cursor-pointer">
              MyDreamBoy AI
            </Link>
            . All Rights Reserved.
          </span>
        </div>
      </div>
    </footer>
  )
}
