import { observer } from '@legendapp/state/react'
import { useBillingScreenTranslation } from 'app/features/ecommerce/hooks/useBillingScreenTranslation'

import { useSubscriptionSection } from '../providers/SubscriptionSectionProvider'

export const BilledText = observer(() => {
  const { t } = useBillingScreenTranslation('subscriptionSection.cards')
  const { selectedPeriod$ } = useSubscriptionSection()
  if (selectedPeriod$.get() === 1) {
    return t('billedMonthly')
  }
  if (selectedPeriod$.get() === 12) {
    return t('billedYearly')
  }
  return t('billedMonths', { period: selectedPeriod$.get() })
})

export const PeriodText = observer(() => {
  const { selectedPeriod$ } = useSubscriptionSection()
  if (selectedPeriod$.get() === 12) {
    return '1 Yr'
  }
  if (selectedPeriod$.get() === 1) {
    return 'Mo'
  }
  return `${selectedPeriod$.get()} Mo`
})
