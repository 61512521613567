import { observer } from '@legendapp/state/react'
import { cn } from '@my/magic-ui'
import { motion } from 'framer-motion'

import { PERIODS } from '../../../constant'
import { useSubscriptionSection } from '../providers/SubscriptionSectionProvider'

export const PeriodTab = observer(() => {
  const { selectedPeriod$ } = useSubscriptionSection()
  return (
    <div className="mx-auto mt-10 flex items-stretch justify-center gap-4 w-full md:w-[650px] p-[10px] bg-[#7756E0]/50 rounded-lg">
      {PERIODS.map((period) => (
        <button
          key={period}
          onClick={() => selectedPeriod$.set(period)}
          className="relative flex-1 py-2 text-white text-base font-medium text-center"
        >
          {selectedPeriod$.get() === period && (
            <motion.span
              className="absolute inset-0 block h-full w-full bg-white rounded-lg z-0"
              layoutId="period-tab"
            />
          )}
          <span
            className={cn(
              'flex flex-col md:flex-row md:gap-2 items-center justify-center gap-1 relative z-10 font-bold transition-all duration-300',
              selectedPeriod$.get() === period &&
                'bg-gradient-to-r from-[#7756E0] to-[#0D009B] text-transparent bg-clip-text'
            )}
          >
            <PeriodText period={period} />
            {period > 1 && (
              <span
                className={cn(
                  'text-white/60 text-xs w-[80%] md:w-auto md:px-2 md:py-1 bg-white/30 rounded transition-all duration-300',
                  selectedPeriod$.get() === period && 'text-[#7756E0]'
                )}
              >
                <PeriodDiscount period={period} />
              </span>
            )}
          </span>
        </button>
      ))}
    </div>
  )
})

const PeriodText = ({ period }: { period: number }) => {
  return period < 12 ? `${period} Mo` : `${period / 12} Yr`
}

const PeriodDiscount = ({ period }: { period: number }) => {
  if (period === 3) {
    return '-20%'
  }
  if (period === 6) {
    return '-40%'
  }
  if (period === 12) {
    return '-50%'
  }
  return null
}
