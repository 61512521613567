import { observer } from '@legendapp/state/react'
import { Subscription_Tier } from '@my/supabase/graphql/gql/graphql'
import { useBillingScreenTranslation } from 'app/features/ecommerce/hooks/useBillingScreenTranslation'
import { IconPlus } from 'app/features/ecommerce/icons/IconPlus'
import { IconPremium } from 'app/features/ecommerce/icons/IconPremium'
import { IconPro } from 'app/features/ecommerce/icons/IconPro'

import { CardItem } from './CardItem'
import { SubscriptionPrices } from '../../../constant'
import { useSubscriptionSection } from '../providers/SubscriptionSectionProvider'
export const Cards = observer(() => {
  const { t } = useBillingScreenTranslation('subscriptionSection.cards')
  const { selectedPeriod$, selectedTier$ } = useSubscriptionSection()
  return (
    <div className="w-full overflow-x-auto no-scrollbar">
      <div className="w-full flex items-stretch	md:items-center lg:justify-center gap-4 mt-5 md:mt-10">
        <CardItem
          onClick={() => selectedTier$.set(Subscription_Tier.Plus)}
          icon={<IconPlus width={36} height={36} />}
          tier={t('plus')}
          credits={300}
          billPrice={`${SubscriptionPrices[selectedPeriod$.get()][Subscription_Tier.Plus]}`}
          benefits={[1, 2, 3, 4, 5]}
          className="order-2 md:order-1"
        />
        <CardItem
          onClick={() => selectedTier$.set(Subscription_Tier.Premium)}
          icon={<IconPremium width={36} height={36} />}
          tier={t('premium')}
          credits={1500}
          billPrice={`${SubscriptionPrices[selectedPeriod$.get()][Subscription_Tier.Premium]}`}
          benefits={[0, 1, 2, 3, 4, 5, 6]}
          highlight
          decoration={
            <div className="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[190px] px-[30px] py-[10px] bg-white text-center rounded">
              <span className="bg-gradient-to-r from-[#7756E0] to-[#0D009B] text-transparent bg-clip-text font-bold">
                MOST POPULAR
              </span>
            </div>
          }
          className="md:-mt-4 order-1 md:order-2"
        />
        <CardItem
          onClick={() => selectedTier$.set(Subscription_Tier.Pro)}
          icon={<IconPro width={36} height={36} />}
          tier={t('pro')}
          credits={8000}
          billPrice={`${SubscriptionPrices[selectedPeriod$.get()][Subscription_Tier.Pro]}`}
          benefits={[0, 1, 2, 3, 4, 5, 6]}
          decoration={
            <div className="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[190px] px-[30px] py-[10px] bg-gradient-to-r from-[#7756E0] to-[#0D009B]  font-bold text-center rounded">
              <span className="text-white font-bold">BEST VALUE</span>
            </div>
          }
          className="order-3 md:order-3"
        />
      </div>
    </div>
  )
})
