export const getClientReferenceId: () => string | undefined = () => {
  const cookie = document.cookie.match('(^|;)\\s*trakdesk_cid\\s*=\\s*([^;]+)')
  if (Array.isArray(cookie)) {
    try {
      const trakdeskCid = JSON.parse(cookie.pop() ?? '')
      const cid = trakdeskCid['cid']
      return cid
    } catch (e) {
      console.log(e)
    }
  }
  return undefined
}
