import { observer } from '@legendapp/state/react'
import { cn } from '@my/magic-ui'
import Modal from 'app/features/ecommerce/components/Modal'
import { useBillingScreenTranslation } from 'app/features/ecommerce/hooks/useBillingScreenTranslation'
import { IconPlus } from 'app/features/ecommerce/icons/IconPlus'
import { motion } from 'framer-motion'

import { PaymentTypes } from './PaymentTypes'
import { OnetimeSubscriptionPrices, PaymentType, SubscriptionPrices } from '../../../constant'
import { useSubscriptionSection } from '../providers/SubscriptionSectionProvider'

export const PaymentTypeModal = observer(() => {
  const { t } = useBillingScreenTranslation('subscriptionSection.paymentTypeModal')

  const { selectedTier$, paymentType$, selectedPeriod$, onPayment, paymentMethod$, isPaying$ } =
    useSubscriptionSection()

  const selectedTab = paymentType$.get() === PaymentType.Recurring ? 0 : 1

  const resetPaymentMethod = () => {
    paymentMethod$.set(null)
    onPayment.current = null
  }

  return (
    <Modal
      isOpen={!!selectedTier$.get()}
      onClose={() => {
        selectedTier$.set(null)
        resetPaymentMethod()
        isPaying$.set(false)
      }}
    >
      <div className="flex flex-col items-center justify-center w-[90vw] md:w-[669px] py-[50px] px-4 md:px-[108px] box-border ">
        <p className="text-white text-3xl md:text-4xl font-medium mb-6">{t('title')}</p>
        <div className="w-full flex items-center justify-center  gap-4 p-[6px] md:p-[10px] bg-[#14152D80]/50 rounded-[20px] relative mb-6">
          <TabIndicator selectedTab={selectedTab} />
          <TabButton
            onClick={() => {
              paymentType$.set(PaymentType.Recurring)
              resetPaymentMethod()
            }}
          >
            <div className="flex flex-col items-start">
              <p className="text-xs md:text-base uppercase">{t('recommend')}</p>
              <p className="flex flex-col items-start md:flex-row text-xs md:text-base">
                {t('recurring')}{' '}
                <span className="px-2 py-1 bg-white/10 rounded-full text-white text-xs font-medium">
                  -20%
                </span>
              </p>
            </div>
          </TabButton>
          <TabButton
            onClick={() => {
              paymentType$.set(PaymentType.Onetime)
              resetPaymentMethod()
            }}
          >
            <p>{t('oneTime')}</p>
          </TabButton>
        </div>

        <div className="mt-4 md:mt-6 w-full flex justify-between items-center">
          <div>
            <p className="text-sm">{t('seletedTier')}</p>
            <div className="mt-2 flex items-center gap-4">
              <div className="p-1 md:p-2 bg-[#0F067D] rounded-xl">
                <IconPlus width={36} height={36} />
              </div>
              <p className="text-3xl md:text-5xl font-medium">Plus</p>
            </div>
          </div>
          <div className="flex flex-col items-center">
            <p className="text-3xl md:text-4xl font-medium">
              $
              {
                (paymentType$.get() === PaymentType.Onetime
                  ? OnetimeSubscriptionPrices
                  : SubscriptionPrices)[selectedPeriod$.get()][selectedTier$.get()]
              }
            </p>
            <p className="text-sm font-medium">Billed Monthly</p>
          </div>
        </div>

        <div className="w-full mt-6">
          <PaymentTypes />
        </div>

        <motion.button
          className={cn(
            'w-full py-[20px] flex items-center justify-center bg-gradient-to-r from-[#7756E0] to-[#0D009B] rounded-full text-white font-medium text-lg mt-6',
            paymentMethod$.get() ? 'opacity-100' : 'opacity-50 cursor-not-allowed'
          )}
          disabled={isPaying$.get() || !paymentMethod$.get()}
          transition={{ type: 'spring', stiffness: 400, damping: 17 }}
          onClick={() => onPayment?.current?.()}
        >
          {isPaying$.get() ? (
            <motion.div
              className="w-6 h-6 border-t-2 border-white rounded-full"
              animate={{ rotate: 360 }}
              transition={{ duration: 1, repeat: Infinity, ease: 'linear' }}
            />
          ) : (
            t('continue')
          )}
        </motion.button>
      </div>
    </Modal>
  )
})

const TabIndicator = ({ selectedTab }: { selectedTab: number }) => {
  return (
    <motion.div
      className={cn(
        'absolute top-[10px] bottom-[10px] left-[10px] w-[calc(50%-15px)] rounded-[15px]',
        'bg-gradient-to-r from-[#7756E0] to-[#0D009B]'
      )}
      initial={false}
      animate={{
        x: selectedTab === 0 ? '0%' : 'calc(100% + 10px)',
      }}
      transition={{ type: 'spring', stiffness: 300, damping: 30 }}
    />
  )
}

const TabButton = ({ children, onClick }: { children: React.ReactNode; onClick: () => void }) => {
  return (
    <button
      className="relative z-10 flex-1 flex flex-col items-center justify-center py-[9.5px] text-xs md:text-sm font-medium transition-colors duration-300 text-white"
      onClick={onClick}
    >
      {children}
    </button>
  )
}
