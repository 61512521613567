import { observer, useComputed } from '@legendapp/state/react'
import { cn } from '@my/magic-ui'
import { api } from 'app/utils/api'
import { getClientReferenceId } from 'app/utils/trackdesk/client'
import { useUser } from 'app/utils/useUser'

import {
  PaymentType,
  GumroadOption,
  CryptoOption,
  G2AOption,
  OthersOption,
  PatreonOption,
} from '../../../constant'
import { useSubscriptionSection } from '../providers/SubscriptionSectionProvider'

export const PaymentTypes = observer(() => {
  const { paymentType$, paymentMethod$, selectedTier$, selectedPeriod$, onPayment, isPaying$ } =
    useSubscriptionSection()
  const { user } = useUser()
  const onPayByCrypto = api.ecommerce.payOnetimeMembershipByCrypto.useMutation()

  const options$ = useComputed<
    { id: string; label: string; available: boolean; onPurchase?: () => void }[]
  >(() => {
    if (paymentType$.get() === PaymentType.Recurring) {
      return [
        { ...PatreonOption, available: false },
        { ...CryptoOption, available: false },
        { ...OthersOption, available: false },
      ]
    } else {
      return [
        {
          ...GumroadOption,
          available: true,
          onPurchase() {
            let url = `https://moggapp.com/pay?variant=Membership_${`${selectedTier$.get()}_${selectedPeriod$.get()}`}&email=${
              user?.email
            }&user=${user?.id}&gid=lytrm`
            const cid = getClientReferenceId()
            if (cid) {
              url = url + `&cid=${cid}`
            }
            window.open(url, '_blank', 'noreferrer')
          },
        },
        {
          ...CryptoOption,
          available: true,
          onPurchase: async () => {
            isPaying$.set(true)
            const url = await onPayByCrypto.mutateAsync({
              tier: selectedTier$.peek()!,
              period: selectedPeriod$.peek(),
            })
            isPaying$.set(false)
            url && window.open(url, '_blank', 'noreferrer')
          },
        },
        { ...G2AOption, available: false },
        { ...OthersOption, available: false },
      ]
    }
  })
  return (
    <div className="flex flex-col gap-2 md:gap-4">
      {options$.get().map((option) => (
        <label
          key={option.id}
          className={cn(
            'flex items-center justify-between px-[30px] py-[15px] md:py-[20px] rounded-full cursor-pointer border border-white/10',
            option.available ? 'hover:bg-white/10' : 'opacity-50 cursor-not-allowed',
            paymentMethod$.get() === option.id ? 'bg-gradient-to-r from-[#7756E0] to-[#0D009B]' : ''
          )}
        >
          <input
            type="radio"
            value={option.id}
            checked={paymentMethod$.get() === option.id}
            onChange={() => {
              console.log('onChange')
              if (option.available) {
                paymentMethod$.set(option.id)
                option.onPurchase && (onPayment.current = option.onPurchase)
              }
            }}
            disabled={!option.available}
            className="sr-only"
          />

          <span className="text-white text-base md:text-lg">{option.label}</span>
          {option.available && (
            <div
              className={cn(
                'w-5 h-5 border-2 rounded-full mr-3 flex items-center justify-center',
                paymentMethod$.get() === option.id ? 'border-white' : 'border-white/50'
              )}
            >
              {paymentMethod$.get() === option.id && (
                <div className="w-3 h-3 bg-white rounded-full" />
              )}
            </div>
          )}
          {!option.available && (
            <span className="ml-auto text-sm text-white px-[10px] py-[4px] bg-white/10">
              Coming Soon
            </span>
          )}
        </label>
      ))}
    </div>
  )
})
