import { i18nInstance } from 'app/utils/i18n'
import { useTranslation } from 'react-i18next'

import enMessages from '../locales/en/billingScreen.json'
import zhMessages from '../locales/zh/billingScreen.json'

i18nInstance.addResourceBundle('en', 'billingScreen', enMessages)
i18nInstance.addResourceBundle('zh', 'billingScreen', zhMessages)

export const useBillingScreenTranslation = (keyPrefix?: string) => {
  return useTranslation('billingScreen', { keyPrefix })
}
