import * as React from 'react'
import { SVGProps, memo } from 'react'
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width ?? 40}
    height={props.height ?? 40}
    fill="none"
    viewBox="0 0 40 40"
    {...props}
  >
    <path
      fill="#DCD8FF"
      d="M10 10C3.45 10 0 15.05 0 20s3.45 10 10 10c3.95 0 7.1-2.8 10-6.1 2.9 3.3 5.95 6.1 10 6.1 6.55 0 10-5.05 10-10s-3.45-10-10-10c-4.05 0-7.1 2.8-10 6.1-2.9-3.3-6.05-6.1-10-6.1Zm0 5c2.1 0 4.4 2.35 6.7 5-2.3 2.65-4.6 5-6.7 5-3.7 0-5-2.7-5-5s1.3-5 5-5Zm20 0c3.7 0 5 2.7 5 5s-1.3 5-5 5c-2.15 0-4.45-2.35-6.7-5 2.3-2.65 4.55-5 6.7-5Z"
    />
  </svg>
)
export const IconPro = memo(SvgComponent)
