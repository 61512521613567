import * as React from 'react'
import { SVGProps } from 'react'
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width ?? 41}
    height={props.height ?? 38}
    fill="none"
    viewBox="0 0 40 40"
    {...props}
  >
    <path
      fill="#DCD8FF"
      d="M9.469 4.734c-.344 0-.68.088-.975.253a1.83 1.83 0 0 0-.697.687l-5.05 8.877a1.683 1.683 0 0 0 .18 1.93l15.78 18.937c.178.212.404.384.663.502a1.999 1.999 0 0 0 1.66 0c.259-.118.485-.29.662-.502L37.474 16.48a1.683 1.683 0 0 0 .177-1.93L32.6 5.675a1.83 1.83 0 0 0-.697-.686 1.99 1.99 0 0 0-.973-.254H9.47Zm-2.23 9.47 3.366-5.919h3.224l-1.578 5.918H7.24Zm1.556 3.55h3.898l2.598 7.795-6.496-7.795Zm7.865 0h7.08l-3.54 10.62-3.54-10.62Zm11.047 0h3.898l-6.496 7.795 2.598-7.795Zm5.454-3.55h-5.012L26.57 8.284h3.224l3.366 5.918Zm-8.916 0h-8.09l1.578-5.919h4.934l1.578 5.918Z"
    />
  </svg>
)
export const IconPremium = React.memo(SvgComponent)
