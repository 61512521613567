import { Subscription_Tier } from '@my/supabase/graphql/gql/graphql'

export const PrivateModelCreditAmount = 500

export const PERIODS = [1, 3, 6, 12]

export enum PaymentType {
  Recurring = 'recurring',
  Onetime = 'onetime',
}

export enum PackageTier {
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
  XLarge = 'xlarge',
}

export const PackageTierToCredits = {
  [PackageTier.Small]: 150,
  [PackageTier.Medium]: 350,
  [PackageTier.Large]: 750,
  [PackageTier.XLarge]: 1500,
}

export const PackageTierToPrice = {
  [PackageTier.Small]: 4.99,
  [PackageTier.Medium]: 9.99,
  [PackageTier.Large]: 19.99,
  [PackageTier.XLarge]: 29.99,
}

export const PatreonOption = { id: 'patreon', label: 'Patreon' }
export const CryptoOption = { id: 'crypto', label: 'Crypto' }
export const OthersOption = { id: 'others', label: 'Others' }
export const G2AOption = { id: 'g2a', label: 'G2A' }
export const GumroadOption = { id: 'gumroad', label: 'Gumroad' }

export const SubscriptionTierToCredits = {
  [Subscription_Tier.Free]: 25,
  [Subscription_Tier.Plus]: 300,
  [Subscription_Tier.Premium]: 1500,
  [Subscription_Tier.Pro]: 8000,
}

export const SubscriptionPrices = {
  1: {
    [Subscription_Tier.Free]: '0',
    [Subscription_Tier.Plus]: '9.99',
    [Subscription_Tier.Premium]: '29.99',
    [Subscription_Tier.Pro]: '99.99',
  },
  3: {
    [Subscription_Tier.Free]: '0',
    [Subscription_Tier.Plus]: '23.99',
    [Subscription_Tier.Premium]: '71.99',
    [Subscription_Tier.Pro]: '239.99',
  },
  6: {
    [Subscription_Tier.Free]: '0',
    [Subscription_Tier.Plus]: '35.99',
    [Subscription_Tier.Premium]: '107.99',
    [Subscription_Tier.Pro]: '347.99',
  },
  12: {
    [Subscription_Tier.Free]: '0',
    [Subscription_Tier.Plus]: '59.99',
    [Subscription_Tier.Premium]: '179.99',
    [Subscription_Tier.Pro]: '599.99',
  },
}

export const OnetimeSubscriptionPrices = {
  1: {
    [Subscription_Tier.Free]: '0',
    [Subscription_Tier.Plus]: '11.99',
    [Subscription_Tier.Premium]: '35.99',
    [Subscription_Tier.Pro]: '119.99',
  },
  3: {
    [Subscription_Tier.Free]: '0',
    [Subscription_Tier.Plus]: '29.99',
    [Subscription_Tier.Premium]: '89.99',
    [Subscription_Tier.Pro]: '299.99',
  },
  6: {
    [Subscription_Tier.Free]: '0',
    [Subscription_Tier.Plus]: '47.99',
    [Subscription_Tier.Premium]: '149.99',
    [Subscription_Tier.Pro]: '479.99',
  },
  12: {
    [Subscription_Tier.Free]: '0',
    [Subscription_Tier.Plus]: '71.99',
    [Subscription_Tier.Premium]: '215.99',
    [Subscription_Tier.Pro]: '719.99',
  },
}
