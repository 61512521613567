import * as React from 'react'
import { SVGProps } from 'react'
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width ?? 40}
    height={props.height ?? 40}
    fill="none"
    viewBox="0 0 40 40"
    {...props}
  >
    <path
      fill="#DCD8FF"
      d="M16.133 22.817 20 19.883l3.85 2.934-1.467-4.75L26.25 15h-4.733L20 10.317 18.483 15H13.75l3.85 3.067-1.467 4.75Zm17.2-6.15C33.333 9.3 27.367 3.333 20 3.333S6.667 9.3 6.667 16.667c0 3.383 1.266 6.45 3.333 8.8v12.866L20 35l10 3.333V25.467a13.267 13.267 0 0 0 3.333-8.8ZM20 6.667c5.517 0 10 4.483 10 10 0 5.516-4.483 10-10 10s-10-4.484-10-10c0-5.517 4.483-10 10-10Zm0 25-6.667 1.7V28.2A13.299 13.299 0 0 0 20 30c2.433 0 4.7-.667 6.667-1.8v5.167L20 31.667Z"
    />
  </svg>
)
export const IconPlus = React.memo(SvgComponent)
